var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "400", persistent: "" },
      model: {
        value: _vm.displayNotificationDialog,
        callback: function ($$v) {
          _vm.displayNotificationDialog = $$v
        },
        expression: "displayNotificationDialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-6" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "px-0 py-0" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-2", attrs: { color: "primary" } },
                        [_vm._v("mdi-information")]
                      ),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ]),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c("v-card-text", { staticClass: "px-0 py-0" }, [
                    _c("p", { staticClass: "body-1" }, [
                      _vm._v(
                        " Satu permohonan pembiayaan e-Wawasan yang baharu telah diterima. "
                      ),
                    ]),
                    _c("p", { staticClass: "body-1" }, [
                      _vm._v(" Sila tekan "),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("TERIMA"),
                      ]),
                      _vm._v(
                        " jika anda ingin menguruskan permohonan ini. Berikut adalah maklumat pemohon: "
                      ),
                    ]),
                    _c("p", { staticClass: "body-1" }, [
                      _vm._v(" Nama Penuh: "),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.application.full_name)),
                      ]),
                    ]),
                    _c("p", { staticClass: "body-1" }, [
                      _vm._v(" Alamat Rumah: "),
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold",
                          staticStyle: { "white-space": "pre-line" },
                        },
                        [_vm._v(_vm._s(_vm.application.home_address))]
                      ),
                    ]),
                    _c("p", { staticClass: "body-1" }, [
                      _vm._v(" Nama majikan: "),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.application.employer_name)),
                      ]),
                    ]),
                    _c("p", { staticClass: "body-1" }, [
                      _vm._v(" Kategori majikan: "),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.application.employer_type_name)),
                      ]),
                    ]),
                    _c("p", { staticClass: "body-1" }, [
                      _vm._v(" Lokasi majikan: "),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.application.employer_location)),
                      ]),
                    ]),
                    _c("p", { staticClass: "body-1" }, [
                      _vm._v(" Jumlah pembiayaan dipohon: "),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("fAmount")(
                              _vm.application.financing_req_amount
                            )
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "align-items-center mb-0",
                  attrs: { cols: "12" },
                },
                [
                  _c("p", { staticClass: "body-1 text-center pb-0 mb-0" }, [
                    _vm._v(" Status : "),
                    _c(
                      "span",
                      { staticClass: "svg-icon svg-icon-2x svg-icon-danger" },
                      [
                        _c("inline-svg", {
                          attrs: {
                            src: _vm.currentLockStatus,
                            fill: _vm.lockStatusColor,
                            title: _vm.lockStatusTitle,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("countdown", {
                    ref: "countdown",
                    attrs: {
                      autoStart: true,
                      emitEvents: true,
                      transform: _vm.transform,
                      time: _vm.countDownMiliseconds,
                    },
                    on: {
                      start: _vm.onCountdownStart,
                      end: _vm.onCountdownEnd,
                      abort: _vm.onCountdownAbort,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _c(
                              "h5",
                              { staticClass: "text-center font-weight-bold" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(props.minutes) +
                                    ":" +
                                    _vm._s(props.seconds) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("v-progress-linear", {
                    attrs: {
                      bottom: "",
                      reverse: "",
                      color: "deep-purple accent-4",
                    },
                    model: {
                      value: _vm.currentProgress,
                      callback: function ($$v) {
                        _vm.currentProgress = $$v
                      },
                      expression: "currentProgress",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass:
                    "d-flex flex-wrap justify-content-end align-items-center",
                  attrs: { cols: "12" },
                },
                [
                  _vm.btnProceed !== false
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            block: "",
                            color: "primary",
                            loading: _vm.isLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onProceed()
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(_vm._s(_vm.proceedButton)),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _c("v-spacer"),
                  _vm.btnRetry !== false
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mb-4",
                          attrs: { block: "", color: "grey", dark: "" },
                          on: {
                            click: function ($event) {
                              return _vm.onRetry()
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(_vm._s(_vm.retryButton)),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _c("v-spacer"),
                  _vm.btnBack !== false
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mb-4",
                          attrs: { block: "", color: "red", dark: "" },
                          on: {
                            click: function ($event) {
                              return _vm.closeNotificationDialog()
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(_vm._s(_vm.backButton)),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("InfoMsgDialog", {
            attrs: {
              title: _vm.msgINFO.title,
              modal: _vm.msgINFO.modal,
              content: _vm.msgINFO.message,
              showProceedButton: true,
              showBackButton: false,
              delayOnProceed: false,
              proceedButton: "OK",
            },
            on: {
              onProceed: function ($event) {
                return _vm.infoProceedOK()
              },
            },
            model: {
              value: _vm.openInfoMsgDialog,
              callback: function ($$v) {
                _vm.openInfoMsgDialog = $$v
              },
              expression: "openInfoMsgDialog",
            },
          }),
          _c("ErrorMsgDialog", {
            attrs: {
              title: _vm.msgERR.title,
              content: _vm.msgERR.message,
              showProceedButton: true,
              showBackButton: false,
              delayOnProceed: false,
              isTypeError: true,
              proceedButton: "OK",
            },
            model: {
              value: _vm.openErrorMsgDialog,
              callback: function ($$v) {
                _vm.openErrorMsgDialog = $$v
              },
              expression: "openErrorMsgDialog",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }