<template>
  <v-dialog v-model="displayNotificationDialog" max-width="400" persistent>
    <v-card class="pa-6">
      <v-row>
        <v-col cols="12">
          <v-card-title class="px-0 py-0">
            <v-icon class="mr-2" color="primary">mdi-information</v-icon>
            <span class="font-weight-bold">{{ title }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="px-0 py-0">
            <p class="body-1">
              Satu permohonan pembiayaan e-Wawasan yang baharu telah diterima.
            </p>
            <p class="body-1">
              Sila tekan
              <span class="font-weight-bold">TERIMA</span> jika anda ingin
              menguruskan permohonan ini. Berikut adalah maklumat pemohon:
            </p>
            <p class="body-1">
              Nama Penuh:
              <span class="font-weight-bold">{{ application.full_name }}</span>
            </p>
            <p class="body-1">
              Alamat Rumah:
              <span class="font-weight-bold" style="white-space: pre-line">{{
                application.home_address
              }}</span>
            </p>
            <p class="body-1">
              Nama majikan:
              <span class="font-weight-bold">{{
                application.employer_name
              }}</span>
            </p>
            <p class="body-1">
              Kategori majikan:
              <span class="font-weight-bold">{{
                application.employer_type_name
              }}</span>
            </p>
            <p class="body-1">
              Lokasi majikan:
              <span class="font-weight-bold">{{
                application.employer_location
              }}</span>
            </p>
            <p class="body-1">
              Jumlah pembiayaan dipohon:
              <span class="font-weight-bold">{{
                application.financing_req_amount | fAmount
              }}</span>
            </p>
          </v-card-text>
        </v-col>
        <v-col cols="12" class="align-items-center mb-0">
          <p class="body-1 text-center pb-0 mb-0">
            Status :
            <span class="svg-icon svg-icon-2x svg-icon-danger">
              <inline-svg
                :src="currentLockStatus"
                :fill="lockStatusColor"
                :title="lockStatusTitle"
              ></inline-svg>
            </span>
          </p>
        </v-col>
        <v-col cols="12">
          <countdown
            ref="countdown"
            :autoStart="true"
            :emitEvents="true"
            :transform="transform"
            :time="countDownMiliseconds"
            @start="onCountdownStart"
            @end="onCountdownEnd"
            @abort="onCountdownAbort"
          >
            <template slot-scope="props">
              <h5 class="text-center font-weight-bold">
                {{ props.minutes }}:{{ props.seconds }}
                <!-- <br/>{{props.totalSeconds}} -->
              </h5>
            </template>
          </countdown>
          <v-progress-linear
            v-model="currentProgress"
            bottom
            reverse
            color="deep-purple accent-4"
          ></v-progress-linear>
        </v-col>
        <v-col
          class="d-flex flex-wrap justify-content-end align-items-center"
          cols="12"
        >
          <v-btn
            block
            class="mb-4"
            color="primary"
            :loading="isLoading"
            v-if="btnProceed !== false"
            @click="onProceed()"
          >
            <span class="font-weight-bold">{{ proceedButton }}</span>
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            block
            class="mb-4"
            color="grey"
            dark
            v-if="btnRetry !== false"
            @click="onRetry()"
          >
            <span class="font-weight-bold">{{ retryButton }}</span>
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            block
            class="mb-4"
            color="red"
            dark
            v-if="btnBack !== false"
            @click="closeNotificationDialog()"
          >
            <span class="font-weight-bold">{{ backButton }}</span>
          </v-btn>
        </v-col>
      </v-row>

      <InfoMsgDialog
        v-model="openInfoMsgDialog"
        :title="msgINFO.title"
        :modal="msgINFO.modal"
        :content="msgINFO.message"
        :showProceedButton="true"
        :showBackButton="false"
        :delayOnProceed="false"
        proceedButton="OK"
        @onProceed="infoProceedOK()"
      ></InfoMsgDialog>

      <ErrorMsgDialog
        v-model="openErrorMsgDialog"
        :title="msgERR.title"
        :content="msgERR.message"
        :showProceedButton="true"
        :showBackButton="false"
        :delayOnProceed="false"
        :isTypeError="true"
        proceedButton="OK"
      ></ErrorMsgDialog>

    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import VueCountdown from "@chenfengyuan/vue-countdown";
import ErrorMsgDialog from "@/view/pages/widgets/MessageDialog.vue";

import {
  GET_APPLICANT_PRELOCK,
  GET_APPLICANT_UNDOLOCK,
  GET_APPLICANT_CONFIRMLOCK,
} from "@/core/services/store/redirector.module";

import { errorMessages } from "@/core/services/helper.service";
Vue.component(VueCountdown.name, VueCountdown);

export default {
  name: "NotificationDialog",

  components: {
    ErrorMsgDialog,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: "",
    },

    showProceedButton: {
      type: Boolean,
      default: true,
    },

    proceedButton: {
      type: String,
      default: "",
    },

    showBackButton: {
      type: Boolean,
      default: false,
    },

    backButton: {
      type: String,
      default: "",
    },

    showRetryButton: {
      type: Boolean,
      default: false,
    },

    retryButton: {
      type: String,
      default: "",
    },

    actionDelaySec: {
      type: Number,
      default: 500,
    },

    idleSec: {
      type: Number,
      default: 60,
    },

    countDownSec: {
      type: Number,
      default: 60,
    },

    routeQuery: {
      type: String,
      // default: () => { return {}; },
      default: "",
    },

    application: {
      type: Object,
      default: () => {
        return {
          id: 0,
          full_name: "",
          home_address: "",
          employer_name: "",
          employer_location: "",
          employer_type_name: "",
          financing_req_amount: 0.0,
        };
      },
    },
  },

  data() {
    return {
      isLoading: false,
      isIdle: false,
      tickerIdle: null,
      tickerProgress: null,
      progressValue: 0,
      btnProceed: false,
      btnBack: false,
      btnRetry: false,
      lockStatus: 0,

      openInfoMsgDialog: false,
      openErrorMsgDialog: false,

      msgINFO: {
        title: "Info",
        modal: false,
        message: "",
        nric: "",
        run: 0,
      },

      msgERR: {
        title: "Ralat",
        message: "",
        nric: "",
        run: 0,
      },

      svg: [
        [
          "/media/svg/icons/custom/lock-check.svg",
          "green",
          "'Lock' Berjaya",
        ],
        [
          "/media/svg/icons/custom/lock-open-remove.svg",
          "red",
          "'Lock' Tidak Berjaya",
        ],
        [
          "/media/svg/icons/custom/lock-open-variant.svg",
          "orange",
          "'Lock' Dilepaskan",
        ]
      ],
    };
  },

  created() {
    this.btnBack = this.showBackButton;
    this.btnRetry = this.showRetryButton;
    this.btnProceed = this.showProceedButton;
    this.progressValue = this.countDownSec + this.idleSec;
    //nfh-console.log("routeQuery: ", this.routeQuery);
  },

  computed: {
    displayNotificationDialog: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },

    countDownMiliseconds: {
      get() {
        return this.countDownSec * 1000;
      },
    },

    currentProgress: {
      get() {
        return (this.progressValue / (this.countDownSec + this.idleSec)) * 100;
      },
    },

    currentLockStatus: {
      get() {
        return this.svg[this.lockStatus][0];
      },
      set(value) {
        this.lockStatus = value;
      }
    },

    lockStatusColor: {
      get() {
        return this.svg[this.lockStatus][1];
      }
    },

    lockStatusTitle: {
      get() {
        return this.svg[this.lockStatus][2];
      }
    }
  },

  watch: {
    value(newValue, oldValue) {
      // xxconsole.log("watcher: ", oldValue, newValue);
      if (newValue) {
        this.isLoading = false;
        if (this.$refs.countdown != undefined) {
          this.$refs.countdown.restart();
        }
      }
    },

    routeQuery(newValue, oldValue) {
      //nfh-console.log("routeQuery: new, old ", newValue, oldValue);
    }
  },

  methods: {
    async closeNotificationDialog() {
      await this.$store
        .dispatch(GET_APPLICANT_UNDOLOCK, {
          sub: this.application.id,
          immediate: true,
        })
        .then(async (response) => {
          if (!response.data.success) {
            this.promptError(response.data.message);
          }
        });

      setTimeout(
        () => {
          this.$emit("onNotifyBack");
          this.isLoading = false;
          this.displayNotificationDialog = false;
          this.$router.replace({ name: this.$route.name }).catch(() => {});
        },
        this.delayOnProceed ? this.actionDelaySec * 1000 : 0
      );
    },

    async onProceed() {
      this.isLoading = true;

      setTimeout(
        async () => {
          await this.$store.dispatch(GET_APPLICANT_CONFIRMLOCK, this.routeQuery)
            .then(async (resp) => {
              if (resp.data.success) {
                this.promptLock(
                  "Permohonan berjaya diterima dan 'Lock'. Sila berhubung terus dengan pemohon untuk tindakan seterusnya.",
                  "Berjaya",
                  true
                );

                this.$emit("onNotifyProceed");
                // await this.$store.dispatch(GET_APPLICATIONS)
                //   .then((resp) => {
                //     this.$store.commit(SET_PAGINATE_APPLICATIONS, resp.data.data);
                //     this.$store.commit(SET_APPLICATIONS, resp.data.data.data);
                //   });
              }
              else {
                this.promptError(resp.data.message, "Tidak Berjaya");
              }
              this.clearRouteQuery();
            });

          this.isLoading = false;
        },
        this.delayOnProceed ? this.actionDelaySec * 1000 : 0
      );
    },

    async onRetry() {
      // xxconsole.log("onRetry");
      this.isLoading = true;
      await this.$store
        .dispatch(GET_APPLICANT_PRELOCK, { sub: this.application.id })
        .then(async (response) => {
          if (response.data.success) {
            // this.progressValue = this.idleSec;
            this.$refs.countdown.restart();

            this.isIdle = true;
            this.progressValue = this.countDownSec + this.idleSec;
            this.$refs.countdown.restart();
            this.currentLockStatus = 0;

            clearTimeout(this.tickerIdle);
            this.tickerIdle = setTimeout(() => {
              if (this.isIdle) {
                this.closeNotificationDialog();
              }
            }, this.idleSec * 1000);
          } else {
            this.promptError(response.data.message);
            this.currentLockStatus = 1;
          }
        });

      setTimeout(
        () => {
          this.$emit("onNotifyRetry");
          this.isLoading = false;
        },
        this.delayOnProceed ? this.actionDelaySec * 1000 : 0
      );
    },

    transform(props) {
      Object.entries(props).forEach(([key, value]) => {
        const digits = value < 10 ? `0${value}` : value; // Adds leading zero
        props[key] = `${digits}`;
      });
      return props;
    },

    onCountdownStart(data) {
      // xxconsole.log("Countdown Started");
      this.isIdle = false;
      this.btnRetry = false;
      this.btnBack = true;
      this.btnProceed = true;
      clearTimeout(this.tickerIdle);
      clearInterval(this.tickerProgress);

      this.tickerProgress = setInterval(() => {
        this.progressValue--;
      }, 1000);
    },

    onCountdownAbort(data) {
      // xxconsole.log("Countdown Aborted");
      this.isIdle = true;
      this.btnBack = false;
      this.btnProceed = false;
      this.btnRetry = true;
      clearTimeout(this.tickerIdle);
      clearInterval(this.tickerProgress);
    },

    onCountdownEnd(data) {
      // xxconsole.log("Countdown Ended");
      this.btnProceed = false;
      this.btnBack = true;
      this.btnRetry = true;
      this.isIdle = true;
      this.currentLockStatus = 2;

      // Set auto close on idling after countdown reached
      this.tickerIdle = setTimeout(() => {
        if (this.isIdle) {
          this.closeNotificationDialog();
        }
      }, this.idleSec * 1000);
    },

    promptInfo(message, title = null, modal = false) {
      this.msgINFO.message = message;
      this.msgINFO.modal = modal;
      if (title) {
        this.msgINFO.title = title;
      }
      this.openInfoMsgDialog = true;
    },

    promptError(message, title = null) {
      this.msgERR.message = errorMessages(message);
      if (title) {
        this.msgERR.title = title;
      }
      this.openErrorMsgDialog = true;
    },

    promptLock(message, title = null, modal = false) {
      this.msgINFO.message = message;
      this.msgINFO.modal = modal;
      if (title) {
        this.msgINFO.title = title;
      }
      this.openLockMsgDialog = true;
    },

    infoProceedOK() {
      //
    },

  },
};
</script>

<style>
</style>